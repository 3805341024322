import * as React from "react"
import { graphql, navigate } from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { 
  Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import PageError from "../components/PageError";
import Helper from '../lib/helper';

const isBrowser = typeof window !== 'undefined';

const NotFoundPage = (props) => {
  const {t} = useTranslation('translation');
  const pathName = isBrowser ? window.location.pathname : '';
  const auth = Helper.getStateAsJSObj(props.session, 'auth', null);
  const setting = Helper.getStateAsJSObj(props.system, 'setting', null);
  
  // Determine the initial route after login for the user depend on the user role
  let route = '/';
  if(Helper.isNotNullAndUndefined(auth, 'data.role'.split('.'))) {
    const roleSetting = Helper.carefullyGetValue(setting, 'data.roles'.split('.'), []).find(item => item.role === auth.data.role);
    if(Helper.isNotNullAndUndefined(roleSetting)) {
      route = Helper.carefullyGetValue(roleSetting, 'value.home_url'.split('.'), '/');
    }
  }
  
  return (
    <Layout is404={true} childrenStyle={{
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <PageError 
        style={{
          minHeight: 150,
          maxWidth: 500
        }}
        title={t('error.message.pageNotFound.title')} 
        message={t('error.message.pageNotFound.desc').replace('{0}', pathName)} 
        actionStyle={{
          justifyContent: 'center'
        }}
        actionControl={
          <Button variant='contained' color='primary' 
            onClick={_ => {
              navigate(route);
            }}
          >
            {t('button.backhome')}
          </Button>
        }
      />
    </Layout>
  );
}

export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(NotFoundPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;